<template>
  <div class="main-class">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="模版名称" prop="userName">
            <el-input
                v-model="queryParams.title"
                placeholder="模版名称"
                clearable
                size="small"
                style="width: 180px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item label="模版名称" prop="status">
            <el-select
                v-model="queryParams.item_label"
                placeholder="模版名称"
                clearable
                size="small"
                style="width: 150px"
            >
              <el-option
                  key="CSS模板"
                  label="CSS模板"
                  value="CSS模板"
              />
              <el-option
                  key="前端模板"
                  label="前端模板"
                  value="前端模板"
              />
              <el-option
                  key="手机模板"
                  label="手机模板"
                  value="手机模板"
              />
              <el-option
                  key="HTML5模板"
                  label="HTML5模板"
                  value="HTML5模板"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <!--按钮-->

        <!--表格-->
        <el-table border v-loading="loading" :data="userList">
          <el-table-column label="编号" align="center" key="id" prop="id"  width="100"/>
          <el-table-column label="名称" align="center" key="title" prop="title" :show-overflow-tooltip="true"/>
          <el-table-column label="模版名称" align="center" key="itemLabel" prop="itemLabel" />
          <el-table-column label="图片" align="center" key="img" prop="img"   >
            <template slot-scope="scope">
            <el-image
                fit="contain"
                style="width: 50px; height: 50px"
                :src="scope.row.img"
                :lazy="true"
                :preview-src-list="[scope.row.img]">
            </el-image>
            </template>
          </el-table-column>


          <el-table-column
              label="操作"
              align="center"
              width="250"
              class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="preview(scope.row)"
                  v-permission="['sys:user:update']"
              >预览</el-button>
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="download(scope.row)"
                  v-permission="['sys:user:delete']"
              >下载</el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <Pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getUserList"
      />
    </el-row>
  </div>
</template>

<script>

import config from "@/axios";
export default {
  name: "User",
  data() {
    return{

      // 部门树选项
      deptOptions: undefined,
      // 表单参数
      form: {},
      roleProps: {
        children: "children",
        label: "name"
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        title: undefined,
        item_label: undefined,
      },
      showSearch: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: null,
      //加载动画
      loading: false,
      roleForm: {
        roleIds: undefined
      },
      roleTreeData: [],
      // 选中数组
      ids: [],
      title: undefined,
      open: false,
      roleOpen: false,
      userId: undefined,
    }
  },
  methods: {
    getUserList(){
      this.loading = true
      this.$axios.get("/templateDetails/details/list", {params: {
          title: this.queryParams.title,
          item_label: this.queryParams.item_label,
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize
        }}).then(res => {
        this.userList = res.data.data.records
        this.total = res.data.data.total
        this.loading = false
      })
    },
    preview(row){
      window.open( "http://www.cssmoban.com"+row.preview)
    },
    download(row){
      // window.open( row.download)
      // var blob = new Blob([row.download],{type: "application/octet-stream"});
      // var link = document.createElement('a');//a标签下载
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "fileName";
      // link.click();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getUserList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        title: undefined,
        item_label: undefined,
      }
      this.handleQuery();
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        deptId: undefined,
        username: undefined,
        phoneNumber: undefined,
        status: 1,
        remark: undefined,
        roleIds: []
      }
      this.resetForm("form")
    },

    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
  },
  created() {
    this.getUserList()
  },
}
</script>

<style lang="scss">
.el-table .el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  margin-left: 5px;
}

.el-table .el-dropdown, .el-icon-arrow-down {
  font-size: 12px;
}
</style>
